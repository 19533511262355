/* eslint-disable react/self-closing-comp */
import React from "react";
import PropTypes from "prop-types";
import SearchBar from "components/search/SearchBar/SearchBar";

import { getHeadingByLevel } from "components/core/Typography";

function LastSearchBar({  level = "3" }) {
	const HeadingTag = getHeadingByLevel(level); 
	return (
		<>
			<div className="margin-top"></div>
			<SearchBar
				className="last-searchbar"
				buttonText="Find Units"
				id="hero-search-bar2"
				label="Search by location"
				theme="lightBlue2"
				segmentCategory="Hero-SearchBar"
			>
				<HeadingTag
					weight="bold"
					size="large"
					largeSize="large"
					align="center"
					color="black"
				>
					Find Storage Units Near You
				</HeadingTag>
			</SearchBar>
		</>
	);
}

LastSearchBar.propTypes = {
	level: PropTypes.string
};
export default LastSearchBar;
